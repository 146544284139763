@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .draftjs {
  }

  .draftjs p {
    margin-bottom: 1em;
  }

  .marquee-item {
    @apply h-[75px] w-[75px] lg:h-[100px] lg:w-[100px] flex items-center justify-center bg-black p-4 mx-[30px] rounded-full my-6;
  }

  .event-ticket::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1.25rem;
    width: 2.5rem;
    margin: auto;
    background-color: #ffffff;
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .coupon::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    height: 3px;
    width: 100%;
    background-color: #ffffff;
    clip-path: polygon(
      0% 0%,
      1% 100%,
      2% 0%,
      3% 100%,
      4% 0%,
      5% 100%,
      6% 0%,
      7% 100%,
      8% 0%,
      9% 100%,
      10% 0%,
      11% 100%,
      12% 0%,
      13% 100%,
      14% 0%,
      15% 100%,
      16% 0%,
      17% 100%,
      18% 0%,
      19% 100%,
      20% 0%,
      21% 100%,
      22% 0%,
      23% 100%,
      24% 0%,
      25% 100%,
      26% 0%,
      27% 100%,
      28% 0%,
      29% 100%,
      30% 0%,
      31% 100%,
      32% 0%,
      33% 100%,
      34% 0%,
      35% 100%,
      36% 0%,
      37% 100%,
      38% 0%,
      39% 100%,
      40% 0%,
      41% 100%,
      42% 0%,
      43% 100%,
      44% 0%,
      45% 100%,
      46% 0%,
      47% 100%,
      48% 0%,
      49% 100%,
      50% 0%,
      51% 100%,
      52% 0%,
      53% 100%,
      54% 0%,
      55% 100%,
      56% 0%,
      57% 100%,
      58% 0%,
      59% 100%,
      60% 0%,
      61% 100%,
      62% 0%,
      63% 100%,
      64% 0%,
      65% 100%,
      66% 0%,
      67% 100%,
      68% 0%,
      69% 100%,
      70% 0%,
      71% 100%,
      72% 0%,
      73% 100%,
      74% 0%,
      75% 100%,
      76% 0%,
      77% 100%,
      78% 0%,
      79% 100%,
      80% 0%,
      81% 100%,
      82% 0%,
      83% 100%,
      84% 0%,
      85% 100%,
      86% 0%,
      87% 100%,
      88% 0%,
      89% 100%,
      90% 0%,
      91% 100%,
      92% 0%,
      93% 100%,
      94% 0%,
      95% 100%,
      96% 0%,
      97% 100%,
      98% 0%,
      99% 100%,
      100% 0%
    );
  }

  .flip-container {
    perspective: 1000px;
  }

  .flip-container.flipped .flipper {
    transform: rotateY(180deg);
  }

  .flip-container,
  .front,
  .back {
    width: 320px;
    height: 470px;
  }

  .flipper {
    transition: 0.6s;
    transform-style: preserve-3d;
    position: relative;
  }

  .front,
  .back {
    position: absolute;
    top: 0;
    left: 0;
  }

  .front {
    z-index: 2;
    /* Front pane, placed above back */
  }

  .back {
    transform: rotateY(180deg);
  }

  .perspective {
    perspective: 1000px;
  }

  .rotate-y-180 {
    transform: rotateY(180deg);
  }
}
